import type { Availability } from "@components/TireAvailabilityDialog";
import type { Estacion, TirePromotion } from "@interfaces/TirePromotion";

export function getAvailability(codigo_producto: string): Promise<Availability> {	
	return fetch(`https://pik.rodi.es/api/stock/list?idproducto=`+codigo_producto)
		.then((response) => {
			if (!response.ok) throw new Error('Error in HTTP request. PIK endpoint isn\'t working!');
			return response.json();
		})
}



